//@import "./_theme-mymeedia.scss";
@import "./_theme-tellit.scss";
@import "./_common.scss";
@import "./_frontend.scss";

// Styles for when isEmbed

body.isEmbed {
	background: transparent;
	height:100%;
	min-height: unset;
}

.isEmbed header {
	background: transparent;
}

.isEmbed.isWithinFrame.isSlideShow {
  height: calc(9/16 * 100vw); 
  max-height: calc(10/16 * 100vw);
}

.app-body.isEmbed {
	margin-top: 0px;
}

.app-body.isEmbed {
	flex-grow: initial;
}

.isSlideShow .app-body.isEmbed {
	margin-top: 0px !important;
}

.showSearch .app-body.isEmbed {
	margin-top: 55px;
}

.isEmbed.isWithinFrame.sidebar-off-canvas .sidebar {
	height: unset;
}

.container-fluid.isWithinFrame > .animated > .row:first-child {
	margin: 0;
}

#thumbnails .isLens.thumb-caption {
	display: none;
}

.h-32 {
	height: 32px;
}